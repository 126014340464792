import { useState, useCallback, useEffect } from 'react';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_PATIENT_F0R_WORKBOARD, GET_PATIENTS, SEARCH_PATIENTS } from 'gql';
import {
    GetPatientForWorkBoardQuery,
    GetPatientForWorkBoardQueryVariables,
    GetPatientsQuery,
    GetPatientsQueryVariables,
    SearchPatientsQuery,
    SearchPatientsQueryVariables,
} from 'types.d';
import { LIST_FILTER_PATIENT } from 'CONST';
import { useUpdateMeClient } from 'hooks';

export const usePatients = (columns?: Record<string, any>) => {
    const { meClient } = useUpdateMeClient();
    const [availablePatients, setAvailablePatients] = useState<any[]>([]);

    // Lazy query to get patients for the workboard.
    const [getPatients, { data: dataPatients, loading: loadPatients }] = useLazyQuery<
        GetPatientForWorkBoardQuery,
        GetPatientForWorkBoardQueryVariables
    >(GET_PATIENT_F0R_WORKBOARD, {
        fetchPolicy: 'cache-and-network',
    });

    // Lazy query to get the full list of patients.
    const [getListPatient, { loading: loadingPatients, data: listPatient, fetchMore }] =
        useLazyQuery<GetPatientsQuery, GetPatientsQueryVariables>(GET_PATIENTS, {
            fetchPolicy: 'cache-and-network',
            onCompleted: (data) => {
                const transformedPatients = data.getPatients.nodes.map((patient) => ({
                    id: patient._id,
                    content: '',
                    patient: { ...patient },
                }));
                setAvailablePatients(transformedPatients);
            },
        });

    const getNameOrder = useCallback(
        (sort: string): { [key: string]: number } => {
            const keySort = [
                'surveyCompleteDate',
                'createdAt',
                'surveyCompleteDate',
                'followUpDate',
                'dob',
            ];
            return {
                [LIST_FILTER_PATIENT[sort].key]: keySort.includes(sort) ? -1 : 1,
            };
        },
        []
    );

    const [searchPatients, { loading: loadingPatientIds, data: dataPatientsSearch }] =
        useLazyQuery<SearchPatientsQuery, SearchPatientsQueryVariables>(SEARCH_PATIENTS, {
            fetchPolicy: 'cache-and-network',
            onCompleted: (patients) => {
                getListPatient({
                    variables: {
                        params: {
                            limit: null,
                            order: meClient.setting?.patientDashboard?.sort
                                ? getNameOrder(meClient.setting?.patientDashboard?.sort)
                                : getNameOrder('createdAt'),
                            condition: {
                                $and: [
                                    {
                                        'owner._id': {
                                            $in: patients.searchPatients_v2,
                                        },
                                    },
                                ],
                            },
                        },
                    },
                });
            },
        });

    // Update availablePatients based on getPatients API result.
    useEffect(() => {
        if (dataPatients?.getPatientForWorkBoard) {
            const transformedPatients = dataPatients.getPatientForWorkBoard.map((patient) => ({
                id: patient._id,
                content: ``,
                patient: { ...patient },
            }));

            setAvailablePatients(transformedPatients);
        }
    }, [dataPatients, columns]);

    return {
        availablePatients,
        loadPatients,
        loadingPatients,
        loadingPatientIds,
        dataPatients,
        dataPatientsSearch,
        getPatients,
        searchPatients,
        setAvailablePatients,
    };
};

export default usePatients;
