import { useQueryCustom, useMutationCustom } from 'hooks';
import {
    GET_FULL_VIEW,
    MOVE_TASK_TO_ANOTHER_COLUMN,
    MOVE_TASK_WITHIN_COLUMN,
    MOVE_COLUMN,
    ADD_COLUMN_TO_BOARD,
    ADD_TASK_TO_COLUMN,
    REMOVE_TASK_FROM_COLUMN,
    UPDATE_KANBAN_BOARD_BY_ID,
    CREATE_KANBAN_BOARD,
    DELETE_KANBAN_BOARD,
    REMOVE_COLUMN_FROM_BOARD,
} from 'gql';
import {
    GetFullViewQuery,
    GetFullViewQueryVariables,
    MoveTaskToAnotherColumnMutation,
    MoveTaskToAnotherColumnMutationVariables,
    MoveTaskWithinColumnMutation,
    MoveTaskWithinColumnMutationVariables,
    MoveColumnMutation,
    MoveColumnMutationVariables,
    AddColumnToBoardMutation,
    AddColumnToBoardMutationVariables,
    AddTaskToColumnMutation,
    AddTaskToColumnMutationVariables,
    RemoveTaskFromColumnMutation,
    RemoveTaskFromColumnMutationVariables,
    UpdateKanbanBoardByIdMutation,
    UpdateKanbanBoardByIdMutationVariables,
    CreateKanbanBoardMutation,
    CreateKanbanBoardMutationVariables,
    DeleteKanbanBoardMutation,
    DeleteKanbanBoardMutationVariables,
    RemoveColumnFromBoardMutation,
    RemoveColumnFromBoardMutationVariables,
} from 'types.d';

const useWorkboard = (boardId?: string) => {
    // Query: Get full view of the board only if boardId is provided.
    const {
        data: fullViewData,
        loading: fullViewLoading,
        error: fullViewError,
        refetch: refetchFullView,
    } = useQueryCustom<GetFullViewQuery, GetFullViewQueryVariables>({
        api: GET_FULL_VIEW,
        variables: { params: { primaryBoardId: boardId } },
        skip: !boardId, // Skip fetching if boardId is not provided.
    });

    const [createWorboard, { loading: loadingCreate }] = useMutationCustom<
        CreateKanbanBoardMutation,
        CreateKanbanBoardMutationVariables
    >({
        api: CREATE_KANBAN_BOARD,
    });

    const [deleteWorkBoard, { loading }] = useMutationCustom<
        DeleteKanbanBoardMutation,
        DeleteKanbanBoardMutationVariables
    >({
        api: DELETE_KANBAN_BOARD,
        textSuccess: 'Delete workboard successfully',
    });

    // Mutations for board interactions
    const [moveTaskToAnotherColumn] = useMutationCustom<
        MoveTaskToAnotherColumnMutation,
        MoveTaskToAnotherColumnMutationVariables
    >({
        api: MOVE_TASK_TO_ANOTHER_COLUMN,
    });

    const [moveTaskWithinColumn] = useMutationCustom<
        MoveTaskWithinColumnMutation,
        MoveTaskWithinColumnMutationVariables
    >({
        api: MOVE_TASK_WITHIN_COLUMN,
    });

    const [moveColumn] = useMutationCustom<
        MoveColumnMutation,
        MoveColumnMutationVariables
    >({
        api: MOVE_COLUMN,
    });

    const [addColumn] = useMutationCustom<
        AddColumnToBoardMutation,
        AddColumnToBoardMutationVariables
    >({
        api: ADD_COLUMN_TO_BOARD,
    });

    const [addTaskToColumn] = useMutationCustom<
        AddTaskToColumnMutation,
        AddTaskToColumnMutationVariables
    >({
        api: ADD_TASK_TO_COLUMN,
    });

    const [removeTaskFromColumn] = useMutationCustom<
        RemoveTaskFromColumnMutation,
        RemoveTaskFromColumnMutationVariables
    >({
        api: REMOVE_TASK_FROM_COLUMN,
    });

    const [updateKanbanBoardById, { loading: loadingUpdateBoard }] = useMutationCustom<
        UpdateKanbanBoardByIdMutation,
        UpdateKanbanBoardByIdMutationVariables
    >({
        api: UPDATE_KANBAN_BOARD_BY_ID,
    });

    const [removeColumnFromBoard] = useMutationCustom<
        RemoveColumnFromBoardMutation,
        RemoveColumnFromBoardMutationVariables
    >({
        api: REMOVE_COLUMN_FROM_BOARD,
    });

    return {
        fullViewData,
        fullViewLoading,
        fullViewError,
        refetchFullView,
        moveTaskToAnotherColumn,
        moveTaskWithinColumn,
        moveColumn,
        addColumn,
        addTaskToColumn,
        removeTaskFromColumn,
        updateKanbanBoardById,
        createWorboard,
        deleteWorkBoard,
        loadingUpdateBoard,
        removeColumnFromBoard
    };
};

export default useWorkboard;
