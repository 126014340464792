import gql from 'graphql-tag';
import { ColumnFragment, PatientDataFragment, WorkBoardFragment } from './fragments';

export const CREATE_KANBAN_BOARD = gql`
  mutation CreateKanbanBoard($params: CreateKanbanBoardInput!) {
    createKanbanBoard(params: $params) {
      _id
      name
      columns {
        ...Column
      }
      createdAt
      updatedAt
    }
  }
  ${ColumnFragment}
`

export const ADD_TASK_TO_COLUMN = gql`
  mutation AddTaskToColumn($params: AddTaskToColumnInput!) {
    addTaskToColumn(params: $params) {
      _id
      name
      columns {
       ...Column
      }
      createdAt
      updatedAt
    }
  }
  ${ColumnFragment}
`

export const REMOVE_TASK_FROM_COLUMN = gql`
  mutation RemoveTaskFromColumn($params: RemoveTaskFromColumnInput!) {
    removeTaskFromColumn(params: $params) {
      _id
      name
      columns {
        ...Column
      }
      createdAt
      updatedAt
    }
  }
  ${ColumnFragment}
`

export const GET_KANBAN_BOARD_BY_ID = gql`
  query GetKanbanBoardById($params: GetKanbanBoardByIdInput!) {
    getKanbanBoardById(params: $params) {
      _id
      name
      columns {
        ...Column
      }
      createdAt
      updatedAt
    }
  }
  ${ColumnFragment}
`

export const MOVE_TASK_TO_ANOTHER_COLUMN = gql`
  mutation MoveTaskToAnotherColumn($params: MoveTaskToAnotherColumnInput!) {
    moveTaskToAnotherColumn(params: $params) {
      _id
      name
      columns {
        ...Column
      }
      createdAt
      updatedAt
    }
  }
  ${ColumnFragment}
`

export const MOVE_TASK_WITHIN_COLUMN = gql`
  mutation MoveTaskWithinColumn($params: MoveTaskWithinColumnInput!) {
    moveTaskWithinColumn(params: $params) {
      _id
      name
      columns {
        ...Column
      }
      createdAt
      updatedAt
    }
  }
  ${ColumnFragment}
`

export const ADD_COLUMN_TO_BOARD = gql`
  mutation AddColumnToBoard($params: AddColumnToBoardInput!) {
    addColumnToBoard(params: $params) {
      _id
      name
      columns {
        ...Column
      }
      createdAt
      updatedAt
    }
  }
  ${ColumnFragment}
`

export const UPDATE_KANBAN_BOARD_BY_ID = gql`
  mutation UpdateKanbanBoardById($boardId: ObjectId!, $updateData: UpdateKanbanBoardInput!) {
    updateKanbanBoardById(boardId: $boardId, updateData: $updateData) {
      _id
      name
      columns {
        ...Column
      }
      createdAt
      updatedAt
    }
  }
  ${ColumnFragment}
`

export const DELETE_KANBAN_BOARD = gql`
  mutation DeleteKanbanBoard($params: DeleteKanbanBoardInput!) {
    deleteKanbanBoard(params: $params){
      success
      warning
    }
  }
`

export const MOVE_COLUMN = gql`
  mutation MoveColumn($params: MoveColumnInput!) {
    moveColumn(params: $params) {
      _id
      name
      columns {
        ...Column
      }
      createdAt
      updatedAt
    }
  }
  ${ColumnFragment}
`

export const GET_PATIENT_F0R_WORKBOARD = gql`
  query GetPatientForWorkBoard($params: GetPatientForWorkBoardInput!) {
    getPatientForWorkBoard(params: $params) {
      _id
      ...PatientData
    }
  }
  ${PatientDataFragment}
`;

export const GET_KANBAN_BOARDS_BY_ORGANIZATION_ID = gql`
  query GetKanbanBoardsByOrganizationId($params: GetKanbanBoardByOrganizationIdInput!) {
    getKanbanBoardsByOrganizationId(params: $params) {
      _id
      name
      createdAt
      updatedAt
    }
  }
`

export const GET_FULL_VIEW = gql`
  query GetFullView($params: GetFullViewInput!) {
    getFullView(params: $params) {
      ...WorkBoard
    }
  }
  ${WorkBoardFragment}
`;

export const REMOVE_COLUMN_FROM_BOARD = gql`
  mutation RemoveColumnFromBoard($params: RemoveColumnFromBoardInput!) {
    removeColumnFromBoard(params: $params) {
      _id
      name
      columns {
        ...Column
      }
      createdAt
      updatedAt
    }
  }
  ${ColumnFragment}
`